<template>
  <v-app>
    <v-container fluid>
      <v-row class="text-center">
        <v-col class="mb-4">
          <h3 class="display-2 font-weight-bold mb-3">NUESTROS PAQUETES</h3>
        </v-col>
      </v-row>
      <v-row>
          <v-col cols="12" sm="1" md="1" class="text-md-center">
            <!-- <h3 class="display-6 font-weight-bold mb-3">NUESTROS PAQUETES DE CONTENIDO</h3> -->
    </v-col>
        <v-col cols="12" sm="2" md="2" class="text-md-center">
                       <v-card  max-width="350" class="mx-auto">
  <v-alert class="text-center" dense dark color="orange darken-1">
                          <strong>BRONCE</strong> - C
                        </v-alert>
    <v-row class="text-center">
        <v-col class="mb-4">
          <h3 class="display-2 font-weight-bold mb-3">1</h3>
          <h3 class="display-2 font-weight-bold mb-3">MES</h3>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    <v-list>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="orange">
           mdi-checkbox-multiple-marked
          </v-icon>
        </v-list-item-icon>
          <v-list-item-title>Tarjeta de Presentacion  
            <v-icon>
           mdi-arrow-right
          </v-icon></v-list-item-title>
          <v-list-item-action>
                <v-list-item-title>1</v-list-item-title>
              </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="orange">
           mdi-checkbox-multiple-marked
          </v-icon>
        </v-list-item-icon>
          <v-list-item-title>Foto del Negocio
             <v-icon>
           mdi-arrow-right
          </v-icon>
          </v-list-item-title>
          <v-list-item-action>
                <v-list-item-title>1</v-list-item-title>
              </v-list-item-action>
          
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="orange">
           mdi-checkbox-multiple-marked
          </v-icon>
        </v-list-item-icon>
          <v-list-item-title>Cargue de Menu
             <v-icon>
           mdi-arrow-right
          </v-icon>
          </v-list-item-title>
          <v-list-item-action>
                <v-list-item-title>1</v-list-item-title>
              </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="orange">
           mdi-checkbox-multiple-marked
          </v-icon>
        </v-list-item-icon>
          <v-list-item-title>Pautas Banner
             <v-icon>
           mdi-arrow-right
          </v-icon>
          </v-list-item-title>
          <v-list-item-action>
                <v-list-item-title>0</v-list-item-title>
              </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
  </v-col>
        <v-col cols="12" sm="2" md="2" class="text-md-center">
                       <v-card  max-width="350" class="mx-auto">
  <v-alert class="text-center" dense dark color="cyan darken-1">
                          <strong>PLATA</strong> - C
                        </v-alert>
    <v-row class="text-center">
        <v-col class="mb-4">
          <h3 class="display-2 font-weight-bold mb-3">6</h3>
          <h3 class="display-2 font-weight-bold mb-3">MESES</h3>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    <v-list>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="cyan">
           mdi-checkbox-multiple-marked
          </v-icon>
        </v-list-item-icon>
          <v-list-item-title>Tarjeta de Presentacion
            <v-icon>
           mdi-arrow-right
          </v-icon>
          </v-list-item-title>
          <v-list-item-action>
                <v-list-item-title>1</v-list-item-title>
              </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="cyan">
           mdi-checkbox-multiple-marked
          </v-icon>
        </v-list-item-icon>
          <v-list-item-title>Foto del Negocio
            <v-icon>
           mdi-arrow-right
          </v-icon>
          </v-list-item-title>
          <v-list-item-action>
                <v-list-item-title>5</v-list-item-title>
              </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="cyan">
           mdi-checkbox-multiple-marked
          </v-icon>
        </v-list-item-icon>
          <v-list-item-title>Cargue de Menu
            <v-icon>
           mdi-arrow-right
          </v-icon>
          </v-list-item-title>
          <v-list-item-action>
                <v-list-item-title>3</v-list-item-title>
              </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="cyan">
           mdi-checkbox-multiple-marked
          </v-icon>
        </v-list-item-icon>
          <v-list-item-title>Pautas Banner
            <v-icon>
           mdi-arrow-right
          </v-icon>
          </v-list-item-title>
          <v-list-item-action>
                <v-list-item-title>6</v-list-item-title>
              </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
  </v-col>
        <v-col cols="12" sm="2" md="2" class="text-md-center">
                       <v-card  max-width="350" class="mx-auto">
  <v-alert class="text-center" dense dark color="green darken-1">
                          <strong>ORO</strong> - C
                        </v-alert>
    <v-row class="text-center">
        <v-col class="mb-4">
          <h3 class="display-2 font-weight-bold mb-3">1</h3>
          <h3 class="display-2 font-weight-bold mb-3">AÑO</h3>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    <v-list>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="green">
           mdi-checkbox-multiple-marked
          </v-icon>
        </v-list-item-icon>
          <v-list-item-title>Tarjeta de Presentacion
            <v-icon>
           mdi-arrow-right
          </v-icon>
          </v-list-item-title>
          <v-list-item-action>
                <v-list-item-title>1</v-list-item-title>
              </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="green">
           mdi-checkbox-multiple-marked
          </v-icon>
        </v-list-item-icon>
          <v-list-item-title>Foto del Negocio
            <v-icon>
           mdi-arrow-right
          </v-icon>
          </v-list-item-title>
          <v-list-item-action>
                <v-list-item-title>10</v-list-item-title>
              </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="green">
           mdi-checkbox-multiple-marked
          </v-icon>
        </v-list-item-icon>
          <v-list-item-title>Cargue de Menu
            <v-icon>
           mdi-arrow-right
          </v-icon>
          </v-list-item-title>
          <v-list-item-action>
                <v-list-item-title>10</v-list-item-title>
              </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="green">
           mdi-checkbox-multiple-marked
          </v-icon>
        </v-list-item-icon>
          <v-list-item-title>Pautas Banner
            <v-icon>
           mdi-arrow-right
          </v-icon>
          </v-list-item-title>
          <v-list-item-action>
                <v-list-item-title>12</v-list-item-title>
              </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
  </v-col>
  <v-divider
  vertical
></v-divider>
        <v-col cols="12" sm="2" md="2" class="text-md-center">
                       <v-card  max-width="350" class="mx-auto">
  <v-alert class="text-center" dense dark color="primary darken-1">
                          <strong>BRONCE</strong> - P
                        </v-alert>
    <v-row class="text-center">
        <v-col class="mb-4">
          <h3 class="display-2 font-weight-bold mb-3">1</h3>
          <h3 class="display-2 font-weight-bold mb-3">DIA</h3>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    <v-list>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">
           mdi-checkbox-multiple-marked
          </v-icon>
        </v-list-item-icon>
          <v-list-item-title>Pauta Banner Ventana Emergente
            <v-icon>
           mdi-arrow-right
          </v-icon>
          </v-list-item-title>
          <v-list-item-action>
                <v-list-item-title>1</v-list-item-title>
              </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">
           mdi-checkbox-multiple-marked
          </v-icon>
        </v-list-item-icon>
          <v-list-item-title>Pauta Banner
            <v-icon>
           mdi-arrow-right
          </v-icon>
          </v-list-item-title>
          <v-list-item-action>
                <v-list-item-title>1</v-list-item-title>
              </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
  </v-col>
        <v-col cols="12" sm="2" md="2" class="text-md-center">
                       <v-card  max-width="350" class="mx-auto">
  <v-alert class="text-center" dense dark color="indigo darken-1">
                          <strong>ORO</strong> - P
                        </v-alert>
    <v-row class="text-center">
        <v-col class="mb-4">
          <h3 class="display-2 font-weight-bold mb-3">1</h3>
          <h3 class="display-2 font-weight-bold mb-3">SEMANA</h3>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    <v-list>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">
           mdi-checkbox-multiple-marked
          </v-icon>
        </v-list-item-icon>
          <v-list-item-title>Pauta Banner Ventana Emergente
            <v-icon>
           mdi-arrow-right
          </v-icon>
          </v-list-item-title>
          <v-list-item-action>
                <v-list-item-title>1</v-list-item-title>
              </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">
           mdi-checkbox-multiple-marked
          </v-icon>
        </v-list-item-icon>
          <v-list-item-title>Pauta Banner
            <v-icon>
           mdi-arrow-right
          </v-icon>
          </v-list-item-title>
          <v-list-item-action>
                <v-list-item-title>1</v-list-item-title>
              </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
  </v-col>
  <v-col cols="12" sm="1" md="1" class="text-md-center">
    </v-col>
      </v-row>
    
<br>
    </v-container>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({

   todosloscomercios: [],

  }),
  components: {
  },
  created(){
    this.VerComercio(1);
  },
  methods:{
   VerComercio: function (codigo) {
      axios.get(`publico/vistacomercio/${codigo}`).then(
        function (response) {
          this.todosloscomercios = response.data;
        }.bind(this)
      );
    },
  },
  computed: {
		
	}
};
</script>
<style >
.v-list-item__subtitle, .v-list-item__title{
      text-overflow: initial!important;
    white-space: initial!important;
}
.marginLeft {
  margin-left: -90px;
}
.mtop {
  margin-top: 100px;
}
.mbottom {
  margin-bottom: 100px;
}
.v-card.borderme {
  border: 2px solid green !important;
}
.v-card.borderout {
  border: 1px solid #d5f0db !important;
}
.v-btn:not(.v-btn--round).v-size--default.add {
  min-width: 0px !important;
}
.theme--light.v-sheet--outlined.mobile {
  border: 2px solid black !important;
}
@media only screen and (max-width: 600px) {
  h2.title1 {
    font-size: 15px;
  }
  h2.title2 {
    font-size: 15px;
  }
  .top {
    margin-top: 20px;
  }
}
@media only screen and (min-width: 600px) {
  .top {
    margin-top: 70px;
  }
}
@media only screen and (min-width: 768px) {
  .top {
    margin-top: 120px;
  }
}
</style>